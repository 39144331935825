@font-face {
    font-family: regular;
    src: url('../Assets/font/AdobeCleanRegular.otf');
  }

  @font-face {
    font-family: bold;
    src: url('../Assets/font/AdobeCleanBold.otf');
  }

@media only screen and (max-width: 450px){ 
    .login-page{
        flex-direction: column; 
    }
}
a{
    cursor: pointer;
}
button{
    cursor:pointer
}
.login-page{
    width: 100%;
    height: 100%;
    display: flex;
    .lg-page-banner{
        width: 67%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../Assets/shared\ image.jpg');
        .banner{
            width: 80%;
            height: 75%;
            margin-top: -10%;
            font-size: 1.3rem;
            font-family: futura-pt-demi;
            label{
                font-family: bold;
                font-size: 28px;
            }
            p{
                font-family: regular;
                font-size: 18px;
            }
            .banner-img{
                width: 100%;
                display: flex;
                justify-content: center;
                // background: url("../Assets/blockchainImg.png");
                img{
                    margin-bottom: 1rem;
                    width: 60%;
                    background-color: aliceblue;
                }
            }
        }
    }

    .lg-page-form::-webkit-scrollbar {
        display: none;
      }
      
    .lg-page-form {
    -ms-overflow-style: none;
    scrollbar-width: none; 
    }

    .lg-page-form{
        width:35%;
        height: 100%;
        overflow-y: scroll;
        box-shadow: 0 3px 6px #00000029 ;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

          
        .as-icon{
            width: 2.2rem;
            position: absolute;
            right:4rem;
            top:2rem;
        }
        .lg-pg-form-box{
            width: 83%;
            height: 90%;
            .form-field{
                margin-top: 3%;
                .form-input{
                    margin-top:2rem;
                    label{
                        font-family: regular;
                        font-size: 16px;
                    }
                    input{
                        width: 80%;
                        font-size: 15px;
                        font-family: regular;
                        margin-top: 1rem;
                        font-size: 14px;
                        height: 2rem;
                        border: 1px solid #70707036;
                        border-radius: 3px;
                        outline: none;
                        padding: 5px 10px;
                    }
                }
            }
            .form-title{
                // color:$text-secondary;
                font-family: bold;
                font-size: 25px;
                margin-top: -10px;
            }
            .form-subtitle{
                font-size: 16px;
                // color:$text-secondary-light;
                font-family: regular;
                line-height: 3rem;
            }
            .form-submit{
                margin-top:1rem;
                text-align: left;
                a{
                    color: #4a4ec0;
                    font-family: bold;
                    font-size: 16px;
                }
            }
            .form-submit1{
                margin-top:1rem;
                text-align:left;
                button{
                    // background-color: #3a3d99;
                    // color: white;
                    background-color: black;
                    color: lightgrey;
                    padding:6px 30px;
                    border-radius: 5px;
                    font-family: bold;
                    // width: 30%;
                    margin-left: 3%;
                }
            }
            .create_accnt{
                margin-top: 2rem;
                a{
                    font-family: regular;
                    span{
                        color: #4347b3;
                        font-family: bold;
                    }
                }
                button{
                    margin-top: 2rem;
                    padding: 10px 30px;
                    border: 2px solid #70707036;
                    border-radius: 3px;
                    background-color: white;
                }
            }
        }
    }

    .new-lg-page-form{
        position: relative;
        margin: auto;
        width:100%;
        height: 100%;  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: scroll;

        .bg-image{
            position: absolute;
            width: 100%;
            height: 100%;
           overflow: hidden;
           rotate: y 180deg;
        //    object-fit: cover;
          
           
        }
        .top-head{
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 5rem;
            color: white;
            width:'60%';
            @media screen and (max-width: 600px) {
                margin-bottom: 1rem;
                line-height: 1.4;
                
            }
            div{
                padding: 5px;
                text-align: center;
            }
        }
          
        .lg-pg-form-box{
            z-index: 10;
            width: 30%;
            border-radius: 10px;
            box-shadow: 0 3px 6px #00000029 ;
           background-color: white;
           display: flex;
           flex-direction: column;
           align-items: center;
           padding: 20px 0px;
            .form-field{
                width: 90%;
                justify-content: center;
            
                .form-input{
                    margin-top:2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    label{
                        font-family: regular;
                        font-size: 16px;
                    }
                    input{
                        width: 80%;
                        font-size: 15px;
                        font-family: regular;
                        // margin-top: 1rem;
                        font-size: 14px;
                        height: 2rem;
                        border: 1px solid #70707036;
                        border-radius: 3px;
                        outline: none;
                        padding: 5px 10px;
                    }
                }
            }
            .form-title{
                // color:$text-secondary;
                font-family: bold;
                font-size: 25px;
                margin-top: -10px;
            }
            .form-subtitle{
                font-size: 16px;
                // color:$text-secondary-light;
                font-family: regular;
                line-height: 3rem;
            }
            .form-submit{
                margin-top:1rem;
                text-align: right;
                width: 100%;
                a{
                    color: #4a4ec0;
                    font-family: bold;
                    font-size: 16px;
                }
            }
            .form-submit1{
                margin-top:1rem;
                text-align:left;
                width: 70%;
                button{
                    // background-color: #3a3d99;
                    // color: white;
                    background-color: black;
                    color: lightgrey;
                    padding:6px 30px;
                    border-radius: 5px;
                    font-family: bold;
                    // width: 30%;
                    // margin-left: 3%;
                }
            }
            .create_accnt{
                margin-top: 1rem;
                a{
                    font-family: regular;
                    span{
                        color: #4347b3;
                        font-family: bold;
                    }
                }
                button{
                    margin-top: 2rem;
                    padding: 10px 30px;
                    border: 2px solid #70707036;
                    border-radius: 3px;
                    background-color: white;
                }
            }
        }

        @media screen and (max-width: 900px) {
            .lg-pg-form-box{
                width: 50%;
            }
            
        }

        @media screen and (max-width: 600px) {
            .lg-pg-form-box{
                width: 80%;
            }
            
        }
    }

}


///mobile view 
@media only screen and (max-width: 600px) {
    .login-page{
    
        justify-content: center;
        .lg-page-banner{
            width: 0%;
            height: 0%;
        }
        .lg-page-form{
            width:100%;
            
          
            .lg-pg-form-box{
                width: 90%;
                .form-field{
                    margin-top: 3%;
                    .form-input{
                        margin-top:2rem;
                        label{
                            font-family: regular;
                            font-size: 16px;
                        }
                        input{
                            width: 90%;
                            font-size: 15px;
                            font-family: regular;
                            margin-top: 1rem;
                            font-size: 14px;
                            height: 2rem;
                            border: 1px solid #70707036;
                            border-radius: 3px;
                            outline: none;
                            padding: 5px 10px;
                        }
                    }
                }
                .form-title{
                    padding-bottom: 1rem;
                }
                .form-subtitle{
                    font-size: 16px;
                    line-height: 1.5rem;
                }
               
                .form-submit1{
                    margin-top:1rem;
                    display: flex;
                    justify-content: center;
                    button{
                        width: 50%;
                    }
                }
            }
        }
    }
}
