@font-face {
    font-family: regular;
    src: url('../Assets/font/AdobeCleanRegular.otf');
  }

  @font-face {
    font-family: bold;
    src: url('../Assets/font/AdobeCleanBold.otf');
  }

  @font-face {
    font-family: italic;
    src: url('../Assets/font/AdobeCleanIt.otf');
  }


@media only screen and (max-width: 750px){ 
    .lg-page-banner{
        width: 0px !important;
        .sideBar{
            display: none;
        }
    }

    .lg-page-form{
        width: 100% !important;
        .first-row{
            label{
                margin-top: 0rem !important;
            }
        }
        .second-row{
            flex-direction: column;
            .pairCard{
                width: 100% !important;
                padding: 1rem 0rem;
                .box-col {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: nowrap;
                    align-items: center;
                    padding: 0px !important;
                    div{
                        flex-shrink:1;
                    }
                }
            }

            .midChain{
                display: none;
            }
        }
        .third-row{
            display: none;
        }
        .third-row-mobile{
            display: block !important;
            
            margin: 1rem;
            border: 1px solid lightgray;
            padding:10px;
            .top-head{
                font-size: 20px;
                font-weight: bold;
            }
            .eachCard{
                // padding: 1rem;
                border: 1px solid lightgray;
                border-radius: 5px;
                margin: 1rem 0rem;
                font-size: 13px;
                font-family: regular;
            }
        }
    }
}
a{
    cursor: pointer;
    text-decoration: none;
    color: lightgrey;
}
button{
    cursor:pointer
}
.dashboard-page{

    width: 100%;
    height: 100%;
    display: flex;
    .lg-page-banner{
        width: 15%;
        height: 100vh;
        display: flex;
        // background-color: #2f327d; 
        background-color: black;
        color: lightgrey;   
        .sideBar{
            width:100%;
            .img-area{
                display:flex;
                justify-content: center;
                padding:1rem;
                margin-top: 3rem;
                img{
                    // width:35%;
                    width: 74px;
                    height: 55px;
                    margin-top: 5.5rem;
                }
                p{
                    font-family: bold;
                    color: white;
                    width: 100%;
                    font-size: 15px;
                    // margin-top: 5%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-wrap: wrap;
                    div{
                        margin: auto;
                        margin-top: 1rem;
                        padding: 0.5rem;
                    }
                    span{
                        font-family: regular;
                        font-size: 12px;
                        // margin-left: 1rem;
                        margin: auto;
                        text-align: center;
                    }
                }
            }
            .menu_area{
                width:100%;
                // margin-top: 80px;
                .row{
                    display: flex;
                    margin-top: 30px;
                    img{
                        width: 22%;
                        height: 15%;
                        margin-top: -16px;
                        margin-left: 1rem;
                    }
                    p{
                        margin-left: 14px !important;
                        font-family: bold;
                        font-size: 15px;
                        width: 90%;
                    }
                }
            }
        }    
    }
    .lg-page-form{
        width:84%;
        height: fit-content;
        box-shadow: 0 3px 6px #00000029 ;
        position: relative;
        // padding:1rem;
        .first-row{
            width:100%;
            display: flex;
            label{
                width:35%;
                font-size:40px;
                font-family: bold;
                margin-left: 1rem;
                margin-top: 1rem;
               
            }
            input{
                width: 40%;
                border:1px solid lightgray;
                font-size: 15px;
                font-family: regular;
                border-radius: 2px;
                font-size: 15px;
                height: 2rem;
                outline: none;
                margin-top: 1rem;
                padding: 2px 10px;
                margin-left: 15%;
            }
            a{
                margin-top: 1rem;
                padding:10px;
                background-color: #21201C;
                border-radius: 2px;
                height: 2rem;
                width: 2rem;
                margin-left: 10px;
            }
        }
        .second-row{
            display: flex;
            margin-top: 1%;
            width: 98%;
            padding: 1rem;
            margin: 0 auto;
            .pairCard{
                width: 50%;
                .eachCard{
                    width: 50%;
                    // margin-left: -10px;
                    margin: 0 0.5rem;
                    .box-col{
                        border: 1px solid lightgray;
                        border-radius: 4px;
                        width:100%;
                        height: 100%;
                        margin-left:2%;
                        padding:10px;
                        p{
                            font-size: 15px;
                            font-family: bold;
                            span{
                                font-family: regular;
                                font-size: 12px;
                            }
                        }
                        .col-md-4{
                            p{
                                font-size: 30px;
                                font-family: bold;
                                margin-top: 32%;
                            }
                        }
                    }
                }
            }
        }
        .third-row{
            border:1px solid lightgray;
            width:96%;
            margin-left: 1%;
            margin-top: 1%;
            padding:5px;
            height: fit-content;
            p{
                font-family: bold;
                font-size: 25px;
                span{
                    font-size: 15px;
                    font-family: italic;
                }
            }
            .row{
                // height: 30px;
                margin-top: -10px;
                .col{
                    center{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        input{
                            margin-top: -15px;
                            font-size: 15px;
                            font-family: regular;
                        }
                        p{
                            color: #000000;
                            font-size: 14px;
                            font-family: regular;
                        }
                    }
                }
                
            }
            .pagination{
                margin-top: 1rem;
                margin-bottom: 1rem;
                .pagin{
                    display: flex;
                    margin-left: 80%;
                    a{
                        padding:5px;
                        background-color: #877bf0;
                    }
                }
            }
        }

        .third-row-mobile{
            display: none;
            p{
                font-family: bold;
                font-size: 25px;
                span{
                    font-size: 15px;
                    font-family: italic;
                }
            }
        }
    }
}