@font-face {
    font-family: regular;
    src: url('../Assets/font/AdobeCleanRegular.otf');
}

@font-face {
    font-family: bold;
    src: url('../Assets/font/AdobeCleanBold.otf');
}


.general-layout {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;


    .gn-layout-header {
        width: 100%;
        height: 5rem;
        // box-shadow: #F2994A 0px 11px 0px 0px;
        // background-color: $theme-primary;
        display: inline-flex;
        align-items: center;
        position: relative;

        .gn-ly-header-left {
            #big-logo {
                margin-left: 1.5rem;
                width: 5rem;
            }

            #min-logo {
                margin-left: 2rem;
                width: 1.8rem;
                display: none;
            }

            @media only screen and (max-width: 600px) {
                #big-logo {
                
                    width: 5rem;
                }

                #min-logo {
                    display: none;
                }
            }
        }

        .gn-ly-header-right {
            position: absolute;
            right: 1rem;
            display: flex;

            .input-box {
                // margin-right: 1rem;
                position: relative;
                display: flex;
                width: 11rem;

                a {
                    background-color: #f0f0f0;
                    border-radius: 30px;
                    height: 50px;
                    width: 48px;
                    margin-top: 13px;

                    img {
                        margin-top: 10px;
                        margin-left: 11px;
                    }
                }

                p {
                    margin-left: 7%;
                    margin-top: 10px;
                    font-size: 15px;
                    font-family: bold;

                    span {
                        font-family: regular;
                        font-size: 12px;
                    }
                }

                .input-box-search {
                    position: absolute;
                    font-size: 0.8rem;
                    margin-left: 0.4rem;
                    margin-top: 0.5rem;

                    &:hover {
                        cursor: pointer;
                    }
                }

                input {
                    border-radius: 5px;
                    font-size: 15px;
                    font-family: regular;
                    height: 1.8rem;
                    width: 11rem;
                    font-size: 0.8rem;
                    outline: none;
                    border: none;
                    transition: width;
                    padding-left: 2rem;
                }

            }

            .bell-box {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 5px;
                background-color: white;
                // @include centerContentBoxWithoutColumn;
                font-size: 1rem;
                color: #3E3841;
                position: relative;
                margin-right: 1rem;

                .bell-icon {
                    transform-origin: top center;
                    animation: ring 1.5s ease;
                }

                .bell-icon.notify {
                    animation: ring 1.5s ease;
                }

                .data-count {
                    position: absolute;
                    font-size: .6rem;
                    // color:$theme-primary;
                    font-weight: 700;
                    top: -10px;
                    right: -10px;
                    padding: 2px 5px;
                    line-height: 100%;
                    // border: 3px $theme-primary solid;
                    border-radius: 60px;
                    background: #FFF;
                    opacity: 1;
                    // content: 23;//attr(data-count);
                    opacity: 1;
                    transform: scale(1);
                    transition: transform, opacity;
                    transition-duration: 0.3s;
                    transition-timing-function: ease-out;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #e7e7e7;
                    transition: all 0.3s ease-in-out;
                }
            }

            .settings-box {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 5px;
                background-color: white;
                margin-top: 8%;
                // @include centerContentBoxWithoutColumn;
                font-size: 1rem;
                color: #3E3841;
                position: relative;

                #icon_drop {
                    font-size: 24px;
                }

                #icon_setting {}
            }

            .settings-box1 {
                width: 3.8rem;
                height: 1.8rem;
                border-radius: 5px;
                background-color: white;
                margin-top: 7%;
                // @include centerContentBoxWithoutColumn;
                font-size: 1rem;
                color: #3E3841;
                position: relative;

                #icon_drop {
                    font-size: 24px;
                }

                #icon_setting {}
            }

            .mobile-menu {
                display: none;
            }

            @media only screen and (max-width: 750px) {
                .bell-box {
                    display: none;
                }

                .settings-box {
                    display: none;
                }

                .settings-box1 {
                    display: none;
                }

                .input-box {
                    display: none;
                }

                .mobile-menu {
                    font-size: 2rem;
                    height: 2.1rem;
                    padding: 5px;
                    margin: 5px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    .dropDownMenu{
                        position: absolute;
                        top: 30px;
                        right: 0.2rem;
                        font-size: 0.9rem;
                        color: lightgrey;
                        z-index: 2;
                        font-weight: bold;
                        background: rgb(0,0,0);
                        -webkit-backdrop-filter: blur(1px);
                        backdrop-filter: blur(1px);
                        border: 1px solid rgba(0,0,0,0.35);
                        border-radius: 5px;
                        padding: 1rem;
                        height: 90vh;
                        width: 600%;
                        div{
                            text-align: end;
                            ul{
                                list-style: none;        
                            }
                            li{
                                padding:0.6rem 0.2rem;
                                text-align: end;
                                border-bottom: 0.1rem solid white;
                            }
                        }
                    }

                    li:hover{
                        background-color: grey;
                        color: black;
                    }
                }
            }
        }

        @media only screen and (max-width: 600px) {
            .gn-ly-header-right {
                right: 0rem;
            }
        }
    }

    .gn-middle-header {
        height: 0.8rem;
        width: 100%;
        // background-image: linear-gradient(to right, #877fb0, #2f327d);
        background-image: linear-gradient(to right, #f8f8f9, #090909);
    }

    @keyframes ring {
        0% {
            transform: rotate(35deg);
        }

        12.5% {
            transform: rotate(-30deg);
        }

        25% {
            transform: rotate(25deg);
        }

        37.5% {
            transform: rotate(-20deg);
        }

        50% {
            transform: rotate(15deg);
        }

        62.5% {
            transform: rotate(-10deg);
        }

        75% {
            transform: rotate(5deg);
        }

        100% {
            transform: rotate(0deg);
        }

    }

    .gn-layout-body {
        width: 100%;
        // padding-top: 4rem;
        height: calc(100% - 4rem - 0.8rem);
    }
}